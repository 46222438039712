import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../services/index';

// Define async thunks

export const getReviewers = createAsyncThunk('user/getData', async (params, thunkAPI) => {
  try {
    const res = await ApiService.ReviewersList(params);
    const data = res?.data?.usersData;
    return {
      data: data.data,
      totalPages: data.totalPages,
      perPage: data.perPage,
      currentPage: data.currentPage,
      totalItems: data.totalItems,
    };

  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getFollowers = createAsyncThunk('user/getFollowers', async (datas, thunkAPI) => {
  try {
    const res = await ApiService.UserFollowers(datas);
    const data = res.data;
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getFollowing = createAsyncThunk('user/getFollowing', async (datas, thunkAPI) => {
  try {
    const res = await ApiService.UserFollowings(datas);
    const data = res.data;
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const ReviewerFollowers = createAsyncThunk('user/reviewerFollowers', async (datas, thunkAPI) => {
  try {
    const res = await ApiService.ReviewersFollowers(datas);
    const data = res.data;
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const ReviewerFollowing = createAsyncThunk('user/reviewerFollowing', async (datas, thunkAPI) => {
  try {
    const res = await ApiService.ReviewersFollowings(datas);
    const data = res.data;
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const ReviewerFilter = createAsyncThunk('user/reviewerFilter', async (datas, thunkAPI) => {
  try {
    const res = await ApiService.ReviewerFilter(datas);
    const data = res.data;
    return { data: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

// Define the initial state
const initialState = {
  data: [],
  totalItems: 1,
  params: {},
  allData: [],
  totalPages: 0,
  currentPage: 1,
  followers: null,
  following: null,
  userFollower: null,
  userFolllowing: null,
  reviewerFilter: null,
  loading: 'idle',
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviewers.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getReviewers.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getFollowers.fulfilled, (state, action) => {
        state.followers = action.payload.data;
      })
      .addCase(getFollowing.fulfilled, (state, action) => {
        state.following = action.payload.data;
      })
      .addCase(ReviewerFollowers.fulfilled, (state, action) => {
        state.userFollower = action.payload.data;
      }).addCase(ReviewerFollowing.fulfilled, (state, action) => {
        state.userFolllowing = action.payload.data;
      })
      .addCase(ReviewerFilter.fulfilled, (state, action) => {
        state.reviewerFilter = action.payload.data;
      });
  }
});

export default userSlice.reducer;