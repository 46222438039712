// ** Reducers Imports
// import contest from '/contest/store';
import restaurant from '../store/restaurantReducer';
import user from '../store/userReducer';
import common from '../store/commonReducer';
import contest from '../store/contestReducer';
const rootReducer = {
  contest,
  restaurant,
  user,
  common
};

export default rootReducer;
