import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../services/index';

// Define async thunks

export const getRestaurants = createAsyncThunk('restaurant/getData', async (params, thunkAPI) => {
    try {
        const response = await ApiService.Restaurants(params);
        if (response.data.status === 200) {
            const data = response.data?.restaurantData;
            const datas = response.data?.paginationData;
            return {
                params,
                data: data.data,
                totalPages: data.totalPages,
                perPage: data.perPage,
                currentPage: data.currentPage,
                totalItems: data.totalItems,
                restaurnatListPages: datas
            };
        } else {
            return thunkAPI.rejectWithValue('Failed to fetch restaurant data');
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getRestaurantDetail = createAsyncThunk('restaurant/getDetail', async (restaurantId, thunkAPI) => {
    try {
        const res = await ApiService.RestaurantDetail(restaurantId);
        const data = res.data;
        return {
            data: data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getUserRestaurantComments = createAsyncThunk('restaurant/getUserRestaurantCommet', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.UserRestaurantReview(datas);
        const data = res.data;
        return {
            data: data,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getRestaurantMustTryFoodList = createAsyncThunk('restaurant/getMustTryFood', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.RestaurantMustTryFood(datas);
        const data = res.data;
        return { data: data };

    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getRestaurantImages = createAsyncThunk('restaurant/getRestaurantImage', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.RestaurantImages(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getRestaurantAllComments = createAsyncThunk('restaurant/getAllComments', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.RestaurantAllComments(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getRestaurantReviewedReviewerList = createAsyncThunk('restaurant/getRestaurantReviewerReviewedList', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.RestaurantReviewedReviewersList(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getChildCommandsList = createAsyncThunk('restaurant/getChildCommands', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.GetCommand(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const FoodType = createAsyncThunk('restaurant/getFoodTypes', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.FoodType(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const RestaurantReviewedFoodList = createAsyncThunk('restaurant/getRestaurnatReviewedFood', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.RestaurantReviewedFood(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

// Define the initial state
const initialState = {
    data: [],
    totalItems: 1,
    params: {},
    allData: [],
    totalPages: 0,
    currentPage: 1,
    restaurantDetail: null,
    userRestaurantComment: null,
    restaurantMustTryFood: null,
    restaurantImage: null,
    restaurantComments: null,
    restaurantReviewedReviewerList: null,
    foodType: null,
    childComments: null,
    reviewedFood: null,
    loading: 'idle',
    error: null,
};

export const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRestaurants.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getRestaurants.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.data = action.payload.data;
                state.params = action.payload.params;
                state.totalPages = action.payload.restaurnatListPages;
                state.currentPage = action.payload.currentPage;
                state.totalItems = action.payload.totalItems;
            })
            .addCase(getRestaurants.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.payload;
            })
            .addCase(getRestaurantDetail.fulfilled, (state, action) => {
                state.restaurantDetail = action.payload.data;
            })
            .addCase(getUserRestaurantComments.fulfilled, (state, action) => {
                state.userRestaurantComment = action.payload.data;
            })
            .addCase(getRestaurantMustTryFoodList.fulfilled, (state, action) => {
                state.restaurantMustTryFood = action.payload.data;
            })
            .addCase(getRestaurantImages.fulfilled, (state, action) => {
                state.restaurantImage = action.payload.data;
            }).addCase(getRestaurantAllComments.fulfilled, (state, action) => {
                state.restaurantComments = action.payload.data;
            }).
            addCase(getRestaurantReviewedReviewerList.fulfilled, (state, action) => {
                state.restaurantReviewedReviewerList = action.payload.data;
            })
            .addCase(getChildCommandsList.fulfilled, (state, action) => {
                state.childComments = action.payload.data;
            })
            .addCase(FoodType.fulfilled, (state, action) => {
                state.foodType = action.payload.data;
            })
            .addCase(RestaurantReviewedFoodList.fulfilled, (state, action) => {
                state.reviewedFood = action.payload.data;
            });
    },
});

export default restaurantSlice.reducer;
