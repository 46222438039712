import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../services/index';

export const getFAQ = createAsyncThunk('common/getAllFAQ', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.FAQ(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const RecommendRestaurant = createAsyncThunk('common/getRecommendRestaurant', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.RecommendedRestaurants(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const Reviewers = createAsyncThunk('common/getReviewers', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.Reviews(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const Recommend = createAsyncThunk('common/getRecommended', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.RecommendedRestaurant(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const HighRating = createAsyncThunk('common/getHighRating', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.ReviewerHighRating(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const RecommendedByMe = createAsyncThunk('common/getRecommendedByMe', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.RecommendedByMe(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

// Define the initial state
const initialState = {
    data: [],
    totalItems: 1,
    params: {},
    allData: [],
    recommendedRestaurant: null,
    reviews: null,
    recommended: null,
    highRating: null,
    recommendedByMe: null,
    totalPages: 0,
    currentPage: 1,
    loading: 'idle',
    error: null,
};

export const commonDataSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFAQ.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.data = action.payload.data;
                state.params = action.payload.params;
                state.totalPages = action.payload.totalPages;
                state.currentPage = action.payload.currentPage;
                state.totalItems = action.payload.totalItems;
            })
            .addCase(RecommendRestaurant.fulfilled, (state, action) => {
                state.recommendedRestaurant = action.payload.data;
            })
            .addCase(Reviewers.fulfilled, (state, action) => {
                state.reviews = action.payload.data;
            })
            .addCase(Recommend.fulfilled, (state, action) => {
                state.recommended = action.payload.data;
            })
            .addCase(HighRating.fulfilled, (state, action) => {
                state.highRating = action.payload.data;
            })
            .addCase(RecommendedByMe.fulfilled, (state, action) => {
                state.recommendedByMe = action.payload.data;
            });
    }
});

export default commonDataSlice.reducer;
